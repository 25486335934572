import { graphql } from 'gatsby';
import React from 'react';
import IntegrationsPage, { IntegrationsPageProps } from '.';

export default (props: IntegrationsPageProps) => (
  <IntegrationsPage {...props} filter="standard" />
);

export const query = graphql`
  query {
    ...IntegrationsQueryFragment
  }
`;
